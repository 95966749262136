html {
  color-scheme: light dark;
}

body {
  color: var(--foreground);
  background: var(--background);
  transition: all 300ms;
}

a {
  color: var(--link);

  &:hover {
    color: var(--link-higher);
  }
}

.skip-link {
  position: absolute;
  top: 1rem;
  left: -9999rem;

  &:focus {
    left: calc(50% - 5rem);
    z-index: var(--layer-2);
  }
}

::selection {
  background-color: var(--link);
  color: var(--background);
  text-shadow: none;
}

::target-text {
  background-color: color-mix(in srgb, var(--link) 25%, transparent);
  color: var(--foreground);
}

// .home,
// .index {
//   > main > section {
//     article {
//       display: flex;
//       flex-direction: column;

//       main {
//         // order: 1;
//         margin-bottom: 0;
//       }

//       header {
//         a {
//           text-decoration: none;
//         }
//       }
//     }
//   }

//   .latest {
//     h3 {
//       @include grid-reset;
//     }

//     article {
//       display: flex;
//       flex-direction: column;

//       header {
//         order: 2;
//       }
//     }
//   }

//   picture {
//     @include pictures;

//     img {
//       @include images;
//     }
//   }
// }

// tags
details {
  margin-bottom: calc(var(--gap) / 2);

  summary {
    &:hover {
      cursor: pointer;
    }
  }
}

.tags {
  list-style: none outside;

  li {
    display: inline-flex;

    a {
      font-size: 80%;
      padding: 0.2rem 0.5rem;
      border-radius: var(--gap);
      border: 1px solid var(--border-color);
      text-decoration: none;
    }
  }
}

summary {
  &:hover {
    cursor: pointer;
  }
}
