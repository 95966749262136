
:root {
  --col: 15rem;
  --gap: clamp(1rem, 5vw, 3rem);
  --font-sans: system-ui,-apple-system,"Segoe UI",roboto,"Helvetica Neue","Noto Sans","Liberation Sans",arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";

  accent-color: var(--accent);

  // colors
  --background: light-dark(hsl(255deg 0% 100%), hsl(210deg 15% 15%));
  --foreground: light-dark(hsl(255deg 10% 20%), hsl(210deg 15% 90%));
  --link: light-dark(#{op.$stone-9}, #{op.$stone-2});
  --border-color: currentcolor;
  
  /* @link https://utopia.fyi/type/calculator?c=320,18,1.2,1240,20,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
  --step--2: clamp(0.7813rem, 0.7747rem + 0.0326vi, 0.8rem);
  --step--1: clamp(0.9375rem, 0.9158rem + 0.1087vi, 1rem);
  --step-0: clamp(1.125rem, 1.0815rem + 0.2174vi, 1.25rem);
  --step-1: clamp(1.35rem, 1.2761rem + 0.3696vi, 1.5625rem);
  --step-2: clamp(1.62rem, 1.5041rem + 0.5793vi, 1.9531rem);
  --step-3: clamp(1.944rem, 1.771rem + 0.8651vi, 2.4414rem);
  --step-4: clamp(2.3328rem, 2.0827rem + 1.2504vi, 3.0518rem);
  --step-5: clamp(2.7994rem, 2.4462rem + 1.7658vi, 3.8147rem);
}

// colors
@supports not (color: light-dark(white, black)) {
  :root {
    --link: #{op.$stone-8};
    --link-higher: #{op.$stone-10};
    --background: #{op.$gray-0};
    --foreground: #{op.$gray-8};
    --middleground: #{op.$gray-6};
    --background-lower: #{op.$gray-1};
    --background-higher: #{op.$gray-0};
    --foreground-higher: #{op.$gray-9};
    --foreground-lower: #{op.$gray-7};
    --shadow-color: #{op.$gray-5};
    --background-transparent: rgb(206 212 218 / 70%);
  }
}