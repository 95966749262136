/*
Hanken Grotesk
https://fonts.google.com/specimen/Hanken+Grotesk
https://www.npmjs.com/package/@fontsource/hanken-grotesk
https://fontsource.org/fonts/hanken-grotesk
*/

// Hanken Grotesk Variable font
// https://fontsource.org/docs/getting-started/sass-import
// @use "../../../../node_modules/@fontsource-variable/hanken-grotesk/scss/mixins" as HankenGrotesk;HankenGroteskVariablefonthttps
// @include HankenGrotesk.faces(
//   $subsets: (
//     cyrillic-ext,
//     latin,
//     latin-ext,
//     vietnamese,
//   ),
//   $weights: (
//     400,
//     500,
//     600,
//     700,
//   ),
//   $styles: all,
//   $directory: "../font/"
// );

body {
  font-feature-settings: "liga", "clig", "kern";
  font-variant-ligatures: common-ligatures;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: var(--font-sans);
  font-variation-settings: "wght" 400;
  font-optical-sizing: auto;
  font-weight: normal;
  font-size: var(--step-0);
  text-size-adjust: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-variation-settings: "wght" 600;
  font-weight: normal;
  margin-top: initial;
}

h1 {
  font-size: var(--step-5);
}

h2 {
  font-size: var(--step-4);
}

h3 {
  font-size: var(--step-3);
}

a[href^="mailto:"] {
  hyphens: none;
}

// p {
//   margin-bottom: 1rem;margin-bottom
// }
