
.navbar {
  &--brand {
    margin-bottom: calc(var(--gap) / 2);

    a {
      text-indent: -300%;
      position: relative;
      display: inline-block;
      overflow: hidden;
      height: 3rem;

      // width: 3rem;

      &::before {
        content: "";
        position: absolute;
        background: transparent url("../img/icon.svg") 0 0 no-repeat;
        background-size: contain;
        width: 3rem;
        height: 3rem;
        top: 0;
        left: 0;

        // transition: transform var(--animation-duration) ease-in-out;
        will-change: transform;
        overflow: hidden;

        @at-root .debug & {
          background-image: url("../img/icon_dev.svg");
        }
      }

      &:hover::before {
        transform: rotate(-15deg);
      }
    }
  }

  &--menu {
    display: flex;
    padding: 0;

    & > li {
      display: inline-block;

      a {
        text-transform: lowercase;
        white-space: nowrap;
        margin-right: calc(var(--gap) / 2);
      }
    }
  }
}
