@charset "UTF-8";
/*!
* Konsonsantenboy
* Krzysztof Jeziorny 2024
* https://konsonantenboy.com
*/
:root {
  --col: 15rem;
  --gap: clamp(1rem, 5vw, 3rem);
  --font-sans: system-ui,-apple-system,"Segoe UI",roboto,"Helvetica Neue","Noto Sans","Liberation Sans",arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  accent-color: var(--accent);
  --background: light-dark(hsl(255deg 0% 100%), hsl(210deg 15% 15%));
  --foreground: light-dark(hsl(255deg 10% 20%), hsl(210deg 15% 90%));
  --link: light-dark(#50514f, #ebedef);
  --border-color: currentcolor;
  /* @link https://utopia.fyi/type/calculator?c=320,18,1.2,1240,20,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
  --step--2: clamp(0.7813rem, 0.7747rem + 0.0326vi, 0.8rem);
  --step--1: clamp(0.9375rem, 0.9158rem + 0.1087vi, 1rem);
  --step-0: clamp(1.125rem, 1.0815rem + 0.2174vi, 1.25rem);
  --step-1: clamp(1.35rem, 1.2761rem + 0.3696vi, 1.5625rem);
  --step-2: clamp(1.62rem, 1.5041rem + 0.5793vi, 1.9531rem);
  --step-3: clamp(1.944rem, 1.771rem + 0.8651vi, 2.4414rem);
  --step-4: clamp(2.3328rem, 2.0827rem + 1.2504vi, 3.0518rem);
  --step-5: clamp(2.7994rem, 2.4462rem + 1.7658vi, 3.8147rem);
}

@supports not (color: light-dark(white, black)) {
  :root {
    --link: #666968;
    --link-higher: #3a3a37;
    --background: #f8f9fa;
    --foreground: #343a40;
    --middleground: #868e96;
    --background-lower: #f1f3f5;
    --background-higher: #f8f9fa;
    --foreground-higher: #212529;
    --foreground-lower: #495057;
    --shadow-color: #adb5bd;
    --background-transparent: rgb(206 212 218 / 70%);
  }
}
html {
  color-scheme: light dark;
}

body {
  color: var(--foreground);
  background: var(--background);
  transition: all 300ms;
}

a {
  color: var(--link);
}
a:hover {
  color: var(--link-higher);
}

.skip-link {
  position: absolute;
  top: 1rem;
  left: -9999rem;
}
.skip-link:focus {
  left: calc(50% - 5rem);
  z-index: var(--layer-2);
}

::selection {
  background-color: var(--link);
  color: var(--background);
  text-shadow: none;
}

::target-text {
  background-color: color-mix(in srgb, var(--link) 25%, transparent);
  color: var(--foreground);
}

details {
  margin-bottom: calc(var(--gap) / 2);
}
details summary:hover {
  cursor: pointer;
}

.tags {
  list-style: none outside;
}
.tags li {
  display: inline-flex;
}
.tags li a {
  font-size: 80%;
  padding: 0.2rem 0.5rem;
  border-radius: var(--gap);
  border: 1px solid var(--border-color);
  text-decoration: none;
}

summary:hover {
  cursor: pointer;
}

/*
Hanken Grotesk
https://fonts.google.com/specimen/Hanken+Grotesk
https://www.npmjs.com/package/@fontsource/hanken-grotesk
https://fontsource.org/fonts/hanken-grotesk
*/
body {
  font-feature-settings: "liga", "clig", "kern";
  font-variant-ligatures: common-ligatures;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: var(--font-sans);
  font-variation-settings: "wght" 400;
  font-optical-sizing: auto;
  font-weight: normal;
  font-size: var(--step-0);
  text-size-adjust: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-variation-settings: "wght" 600;
  font-weight: normal;
  margin-top: initial;
}

h1 {
  font-size: var(--step-5);
}

h2 {
  font-size: var(--step-4);
}

h3 {
  font-size: var(--step-3);
}

a[href^="mailto:"] {
  hyphens: none;
}

.grid {
  display: flex;
  flex-flow: column wrap;
  gap: var(--gap);
}
@supports (grid-area: auto) {
  .grid {
    display: grid;
    --content: min(80ch, 100% - var(--gap) * 2);
    --feature: minmax(0, 10rem);
    --full: minmax(var(--gap), 1fr);
    grid-template-columns: [full-start] var(--full) [feature-start] var(--feature) [content-start] var(--content) [content-end] var(--feature) [feature-end] var(--full) [full-end];
  }
  .grid .layout-content {
    grid-column: content;
  }
  .grid .layout-popout {
    grid-column: popout;
  }
  .grid .layout-featured {
    grid-column: feature;
  }
  .grid .layout-full {
    grid-column: full;
  }
}

.grid-auto {
  flex-flow: row wrap;
}
@supports (grid-area: auto) {
  .grid-auto {
    grid-template-columns: repeat(auto-fill, minmax(var(--col), 1fr));
  }
}

.grid-reset {
  grid-column: -1/1;
}

html, body {
  height: 100%;
}

body {
  display: grid;
  gap: 1rem;
  grid-template-rows: auto 1fr auto;
  scrollbar-gutter: stable;
  height: 100%;
}
body > header, body > footer, body > main {
  display: flex;
  flex-flow: column wrap;
  gap: var(--gap);
  gap: 0;
  align-content: flex-start;
}
@supports (grid-area: auto) {
  body > header, body > footer, body > main {
    display: grid;
    --content: min(80ch, 100% - var(--gap) * 2);
    --feature: minmax(0, 10rem);
    --full: minmax(var(--gap), 1fr);
    grid-template-columns: [full-start] var(--full) [feature-start] var(--feature) [content-start] var(--content) [content-end] var(--feature) [feature-end] var(--full) [full-end];
  }
  body > header .layout-content, body > footer .layout-content, body > main .layout-content {
    grid-column: content;
  }
  body > header .layout-popout, body > footer .layout-popout, body > main .layout-popout {
    grid-column: popout;
  }
  body > header .layout-featured, body > footer .layout-featured, body > main .layout-featured {
    grid-column: feature;
  }
  body > header .layout-full, body > footer .layout-full, body > main .layout-full {
    grid-column: full;
  }
}
body > main > * {
  grid-column: content;
}
body > header, body > footer {
  padding-block: 1rem;
}
body > header > *, body > footer > * {
  grid-column: feature;
  gap: 1rem;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  width: 1rem;
  height: 1rem;
  fill: currentcolor;
  stroke: currentcolor;
  stroke-width: 2;
  margin-top: -0.25rem;
}
.is-active > .icon {
  fill: var(--accent);
}
a .icon {
  fill: currentcolor;
}
a:hover .icon {
  fill: currentcolor;
}
.disabled a .icon {
  fill: var(--middleground);
}
.icon.wagtail-userbar-icon {
  fill: unset;
}
.icon--smd {
  width: 1.25rem;
  height: 1.25rem;
}
.icon--md {
  width: 1.75rem;
  height: 1.75rem;
}
.icon--lg {
  width: 2rem;
  height: 2rem;
}
.icon--xl {
  width: 3rem;
  height: 3rem;
}

.navbar--brand {
  margin-bottom: calc(var(--gap) / 2);
}
.navbar--brand a {
  text-indent: -300%;
  position: relative;
  display: inline-block;
  overflow: hidden;
  height: 3rem;
}
.navbar--brand a::before {
  content: "";
  position: absolute;
  background: transparent url("../img/icon.svg") 0 0 no-repeat;
  background-size: contain;
  width: 3rem;
  height: 3rem;
  top: 0;
  left: 0;
  will-change: transform;
  overflow: hidden;
}
.debug .navbar--brand a::before {
  background-image: url("../img/icon_dev.svg");
}

.navbar--brand a:hover::before {
  transform: rotate(-15deg);
}
.navbar--menu {
  display: flex;
  padding: 0;
}
.navbar--menu > li {
  display: inline-block;
}
.navbar--menu > li a {
  text-transform: lowercase;
  white-space: nowrap;
  margin-right: calc(var(--gap) / 2);
}

body > footer > nav {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
body > footer > nav button {
  margin-inline-start: auto;
}

.gallery {
  display: flex;
  flex-flow: column wrap;
  gap: var(--gap);
  flex-flow: row wrap;
}
@supports (grid-area: auto) {
  .gallery {
    display: grid;
    --content: min(80ch, 100% - var(--gap) * 2);
    --feature: minmax(0, 10rem);
    --full: minmax(var(--gap), 1fr);
    grid-template-columns: [full-start] var(--full) [feature-start] var(--feature) [content-start] var(--content) [content-end] var(--feature) [feature-end] var(--full) [full-end];
  }
  .gallery .layout-content {
    grid-column: content;
  }
  .gallery .layout-popout {
    grid-column: popout;
  }
  .gallery .layout-featured {
    grid-column: feature;
  }
  .gallery .layout-full {
    grid-column: full;
  }
}
@supports (grid-area: auto) {
  .gallery {
    grid-template-columns: repeat(auto-fit, minmax(var(--col), 1fr));
  }
}
.gallery article {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.gallery article main {
  order: 1;
}
.gallery article header {
  order: 2;
}
.gallery article header a {
  text-decoration: none;
}
.gallery article header h2 {
  margin: 0;
  font-size: var(--step-0);
}
.gallery figure {
  margin: 0;
}
.gallery picture figcaption {
  font-size: 80%;
  color: var(--foreground-lower);
}
.gallery picture img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 1;
}

@media (scripting: enabled) {
  [loading=lazy] {
    opacity: 0;
    transition: opacity 0.5s;
  }
}
.content__list nav {
  margin-block: 1rem;
}
.content__list nav .pagination {
  margin-bottom: 0;
}

body > footer .js-theme {
  display: flex;
  padding: 0;
  border: none;
  margin-inline-start: auto;
}
body > footer .js-theme button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

@media print {
  @page {
    size: a4;
    @bottom-center {
      content: "Seite " counter(page);
      margin-bottom: 1cm;
      font-size: 7pt;
      font-family: sans-serif;
    }
  }
  *,
  *::before,
  *::after {
    background: transparent !important;
    color: black !important;
    filter: none !important;
    text-shadow: none !important;
  }
  :root {
    background-color: white;
    color: black;
  }
  html {
    height: unset;
  }
  body {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 11pt;
    height: unset;
    margin-top: 0;
  }
  body > header {
    margin-bottom: 1cm;
  }
  .wrap {
    margin: 0;
    display: block;
    height: unset;
    min-height: unset;
  }
  .wrap > main {
    margin: 0;
    height: unset;
    max-width: unset;
  }
  #top,
  .messages,
  .wrap > header,
  .breadcrumb,
  .pagination,
  .icon,
  button,
  .wrap > footer {
    display: none;
    visibility: hidden;
  }
  article,
  tr,
  td,
  dt,
  dd {
    break-inside: avoid-page;
  }
  h1 {
    font-size: 13pt;
    font-weight: 700;
  }
  address {
    font-style: normal;
  }
  th,
  dt,
  strong,
  b {
    font-weight: 600;
  }
  em,
  i {
    font-style: italic;
  }
}
/*! PhotoSwipe main CSS by Dmytro Semenov | photoswipe.com */
.pswp {
  --pswp-bg: #000;
  --pswp-placeholder-bg: #222;
  --pswp-root-z-index: 100000;
  --pswp-preloader-color: rgba(79, 79, 79, 0.4);
  --pswp-preloader-color-secondary: rgba(255, 255, 255, 0.9);
  /* defined via js:
  --pswp-transition-duration: 333ms; */
  --pswp-icon-color: #fff;
  --pswp-icon-color-secondary: #4f4f4f;
  --pswp-icon-stroke-color: #4f4f4f;
  --pswp-icon-stroke-width: 2px;
  --pswp-error-text-color: var(--pswp-icon-color);
}

/*
	Styles for basic PhotoSwipe (pswp) functionality (sliding area, open/close transitions)
*/
.pswp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--pswp-root-z-index);
  display: none;
  touch-action: none;
  outline: 0;
  opacity: 0.003;
  contain: layout style size;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Prevents focus outline on the root element,
  (it may be focused initially) */
.pswp:focus {
  outline: 0;
}

.pswp * {
  box-sizing: border-box;
}

.pswp img {
  max-width: none;
}

.pswp--open {
  display: block;
}

.pswp,
.pswp__bg {
  transform: translateZ(0);
  will-change: opacity;
}

.pswp__bg {
  opacity: 0.005;
  background: var(--pswp-bg);
}

.pswp,
.pswp__scroll-wrap {
  overflow: hidden;
}

.pswp__scroll-wrap,
.pswp__bg,
.pswp__container,
.pswp__item,
.pswp__content,
.pswp__img,
.pswp__zoom-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pswp__img,
.pswp__zoom-wrap {
  width: auto;
  height: auto;
}

.pswp--click-to-zoom.pswp--zoom-allowed .pswp__img {
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.pswp--click-to-zoom.pswp--zoomed-in .pswp__img {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.pswp--click-to-zoom.pswp--zoomed-in .pswp__img:active {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

/* :active to override grabbing cursor */
.pswp--no-mouse-drag.pswp--zoomed-in .pswp__img,
.pswp--no-mouse-drag.pswp--zoomed-in .pswp__img:active,
.pswp__img {
  cursor: -webkit-zoom-out;
  cursor: -moz-zoom-out;
  cursor: zoom-out;
}

/* Prevent selection and tap highlights */
.pswp__container,
.pswp__img,
.pswp__button,
.pswp__counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pswp__item {
  /* z-index for fade transition */
  z-index: 1;
  overflow: hidden;
}

.pswp__hidden {
  display: none !important;
}

/* Allow to click through pswp__content element, but not its children */
.pswp__content {
  pointer-events: none;
}

.pswp__content > * {
  pointer-events: auto;
}

/*

  PhotoSwipe UI

*/
/*
	Error message appears when image is not loaded
	(JS option errorMsg controls markup)
*/
.pswp__error-msg-container {
  display: grid;
}

.pswp__error-msg {
  margin: auto;
  font-size: 1em;
  line-height: 1;
  color: var(--pswp-error-text-color);
}

/*
class pswp__hide-on-close is applied to elements that
should hide (for example fade out) when PhotoSwipe is closed
and show (for example fade in) when PhotoSwipe is opened
 */
.pswp .pswp__hide-on-close {
  opacity: 0.005;
  will-change: opacity;
  transition: opacity var(--pswp-transition-duration) cubic-bezier(0.4, 0, 0.22, 1);
  z-index: 10; /* always overlap slide content */
  pointer-events: none; /* hidden elements should not be clickable */
}

/* class pswp--ui-visible is added when opening or closing transition starts */
.pswp--ui-visible .pswp__hide-on-close {
  opacity: 1;
  pointer-events: auto;
}

/* <button> styles, including css reset */
.pswp__button {
  position: relative;
  display: block;
  width: 50px;
  height: 60px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  cursor: pointer;
  background: none;
  border: 0;
  box-shadow: none;
  opacity: 0.85;
  -webkit-appearance: none;
  -webkit-touch-callout: none;
}

.pswp__button:hover,
.pswp__button:active,
.pswp__button:focus {
  transition: none;
  padding: 0;
  background: none;
  border: 0;
  box-shadow: none;
  opacity: 1;
}

.pswp__button:disabled {
  opacity: 0.3;
  cursor: auto;
}

.pswp__icn {
  fill: var(--pswp-icon-color);
  color: var(--pswp-icon-color-secondary);
}

.pswp__icn {
  position: absolute;
  top: 14px;
  left: 9px;
  width: 32px;
  height: 32px;
  overflow: hidden;
  pointer-events: none;
}

.pswp__icn-shadow {
  stroke: var(--pswp-icon-stroke-color);
  stroke-width: var(--pswp-icon-stroke-width);
  fill: none;
}

.pswp__icn:focus {
  outline: 0;
}

/*
	div element that matches size of large image,
	large image loads on top of it,
	used when msrc is not provided
*/
div.pswp__img--placeholder,
.pswp__img--with-bg {
  background: var(--pswp-placeholder-bg);
}

.pswp__top-bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 10;
  /* allow events to pass through top bar itself */
  pointer-events: none !important;
}

.pswp__top-bar > * {
  pointer-events: auto;
  /* this makes transition significantly more smooth,
     even though inner elements are not animated */
  will-change: opacity;
}

/*

  Close button

*/
.pswp__button--close {
  margin-right: 6px;
}

/*

  Arrow buttons

*/
.pswp__button--arrow {
  position: absolute;
  top: 0;
  width: 75px;
  height: 100px;
  top: 50%;
  margin-top: -50px;
}

.pswp__button--arrow:disabled {
  display: none;
  cursor: default;
}

.pswp__button--arrow .pswp__icn {
  top: 50%;
  margin-top: -30px;
  width: 60px;
  height: 60px;
  background: none;
  border-radius: 0;
}

.pswp--one-slide .pswp__button--arrow {
  display: none;
}

/* hide arrows on touch screens */
.pswp--touch .pswp__button--arrow {
  visibility: hidden;
}

/* show arrows only after mouse was used */
.pswp--has_mouse .pswp__button--arrow {
  visibility: visible;
}

.pswp__button--arrow--prev {
  right: auto;
  left: 0px;
}

.pswp__button--arrow--next {
  right: 0px;
}

.pswp__button--arrow--next .pswp__icn {
  left: auto;
  right: 14px;
  /* flip horizontally */
  transform: scale(-1, 1);
}

/*

  Zoom button

*/
.pswp__button--zoom {
  display: none;
}

.pswp--zoom-allowed .pswp__button--zoom {
  display: block;
}

/* "+" => "-" */
.pswp--zoomed-in .pswp__zoom-icn-bar-v {
  display: none;
}

/*

  Loading indicator

*/
.pswp__preloader {
  position: relative;
  overflow: hidden;
  width: 50px;
  height: 60px;
  margin-right: auto;
}

.pswp__preloader .pswp__icn {
  opacity: 0;
  transition: opacity 0.2s linear;
  animation: pswp-clockwise 600ms linear infinite;
}

.pswp__preloader--active .pswp__icn {
  opacity: 0.85;
}

@keyframes pswp-clockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*

  "1 of 10" counter

*/
.pswp__counter {
  height: 30px;
  margin-top: 15px;
  margin-inline-start: 20px;
  font-size: 14px;
  line-height: 30px;
  color: var(--pswp-icon-color);
  text-shadow: 1px 1px 3px var(--pswp-icon-color-secondary);
  opacity: 0.85;
}

.pswp--one-slide .pswp__counter {
  display: none;
}

/* focus with tab key */
*:focus-visible {
  outline: currentcolor solid 0.25rem;
  outline-offset: 0.25rem;
}

@media (prefers-reduced-motion: no-preference) {
  *:focus-visible {
    animation: outline-bounce 0.5s;
  }
}
@keyframes outline-bounce {
  0% {
    outline-offset: 0.25rem;
  }
  50% {
    outline-offset: 0.5rem;
  }
  100% {
    outline-offset: 0.25rem;
  }
}
/* mouse click */
:focus:not(:focus-visible) {
  outline: none;
}

/* Select all elements in the page and reduce or remove motion. */
/* Important note: This has no effect in browsers that don’t support prefers-reduced-motion */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
@keyframes smoothscroll1 {
  from, to {
    scroll-behavior: smooth;
  }
}
@keyframes smoothscroll2 {
  from, to {
    scroll-behavior: smooth;
  }
}
html {
  animation: smoothscroll1 1s;
}

html:focus-within {
  animation-name: smoothscroll2;
  scroll-behavior: smooth;
}

svg {
  pointer-events: none;
}