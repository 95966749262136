// Galleries
.gallery {
  @include grid;
  @include gridAutoFit;

  article {
    @include flexColumn;

    align-items: center;

    main {
      order: 1;
    }

    header {
      order: 2;

      a {
        text-decoration: none;
      }

      h2 {
        margin: 0;
        font-size: var(--step-0); 
      }
    }
  }

  figure {
    margin: 0;
  }

  picture {
    figcaption {
      @include figcaptions;
    }

    img {
      @include images;

      object-fit: cover;
      aspect-ratio: 1;
    }
  }
}

// Animate native lazy loading
// https://medienbaecker.com/articles/animate-native-lazy-loading
@media (scripting: enabled) {
  [loading="lazy"] {
    opacity: 0;
    transition: opacity 0.5s;
  }
}
