// Icons
.icon {
  display: inline-block;
  vertical-align: middle;
  width: 1rem;
  height: 1rem;
  fill: currentcolor;
  stroke: currentcolor;
  stroke-width: 2;
  margin-top: -0.25rem;

  .is-active > & {
    fill: var(--accent);
  }

  a & {
    fill: currentcolor;
  }

  a:hover & {
    fill: currentcolor;
  }

  .disabled a & {
    fill: var(--middleground);
  }

  // Wagtail Userbar icon nicht geändert
  &.wagtail-userbar-icon {
    fill: unset;
  }

  // Sizes
  &--smd {
    width: #{op.$size-4}; // 1.5rem
    height: #{op.$size-4}; // 1.5rem
  }

  &--md {
    width: #{op.$size-6}; // 1.75rem
    height: #{op.$size-6}; // 1.75rem
  }

  &--lg {
    width: #{op.$size-7}; // 2rem
    height: #{op.$size-7}; // 2rem
  }

  &--xl {
    width: #{op.$size-8}; // 3rem
    height: #{op.$size-8}; // 3rem
  }
}
