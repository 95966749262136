html, body {
  height: 100%;
}

body {
  display: grid;
  gap: 1rem;
  grid-template-rows: auto 1fr auto;
  scrollbar-gutter: stable;
  height: 100%;

  & > header,
  & > footer,
  & > main {
    @include grid;

    gap: 0;
    align-content: flex-start;
  }

  & > main {
    > * {
      grid-column: content;
    }
  }

  // Header, footer wider
  & > header,
  & > footer {
    & > * {
      grid-column: feature;
      gap: 1rem;
    }

    padding-block: 1rem;
  }
}