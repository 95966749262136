// photo mixins
@mixin full-width {
  width: 100%;
  grid-column: 1 / -1;
}

@mixin pictures {
  // box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);box-shadow
  border: solid 1rem var(--border-color);
  display: block;
}

@mixin figcaptions {
  font-size: 80%;
  color: var(--foreground-lower);
}

@mixin images {
  max-width: 100%;
  height: auto;
}

@mixin visually-hidden {
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	width: 1px !important;
	clip: rect(0,0,0,0) !important;
	border: 0 !important;
	white-space: nowrap !important;
}