// a11y

/* focus with tab key */
*:focus-visible {
  outline: currentcolor solid .25rem;
  outline-offset: .25rem;
}

@media (prefers-reduced-motion: no-preference) {
  *:focus-visible {
    animation: outline-bounce .5s;
  }
}

@keyframes outline-bounce {
  0% { outline-offset: .25rem }
  50% { outline-offset: .5rem }
  100% { outline-offset: .25rem }
}

/* mouse click */
:focus:not(:focus-visible) {
  outline: none;
}

/* Select all elements in the page and reduce or remove motion. */

/* Important note: This has no effect in browsers that don’t support prefers-reduced-motion */

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

// smooth scrolling
// https://schepp.dev/posts/smooth-scrolling-and-page-search/

@keyframes smoothscroll1 {
  from,
  to {
    scroll-behavior: smooth;
  }
}

@keyframes smoothscroll2 {
  from,
  to {
    scroll-behavior: smooth;
  }
}

html {
  animation: smoothscroll1 1s;
}

html:focus-within {
  animation-name: smoothscroll2;
  scroll-behavior: smooth;
}

svg {
  pointer-events: none;
}
