body {
  & > footer {
    > nav {
      @include flex;
  
      flex-wrap: wrap;
      gap: 0.5rem;
  
      button {
        margin-inline-start: auto;
      }
    }
  }
}