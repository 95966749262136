body {
  & > footer {
    .js-theme {
      @include flex;
      padding: 0;
      border: none;
      margin-inline-start: auto;

      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
    }
  }
}
